<template>
  <div class="container">
    <ul>
        <div>
           <li v-for="(item,index) in nav" :key="index" :class="index==activeIndex?'actives':''" >
                <span @click="tab(item,index)">{{item.name}}</span>
            </li> 
        </div>
        
        <li class="login" v-show="login">
            <img :src="profile_picture" alt="">
            <span @click="logOut">退出</span>
        </li>
        <li class="login" v-show="!login" @click="gologin">
            登录
        </li>
    </ul>
  </div>
</template>

<script>
import axios from 'axios'
export default {
    data(){
        return{
            nav:[
                {path:'/',name:'赛事中心'},
                {path:'/ranking',name:'排行榜'},
            ],
            activeIndex:0,
            login:false,
            username:'',
            profile_picture:'',
        fileList: [],
        }
    },
    mounted(){
        if(localStorage.getItem('token')){
            this.login = true
            this.nav = [
                {path:'/',name:'赛事中心'},
                {path:'/ranking',name:'排行榜'},
                {path:'/user',name:'个人中心'},
                {path:`/writeup`,name:'WriteUp'},
            ],
            this.userinfo()
        }
        if(localStorage.getItem('tab')){
            this.activeIndex = localStorage.getItem('tab')
        }
    },
    methods:{
        userinfo(){
            this.$get('ctfd/get_profile').then(res=>{
                this.username = res.data.username
                this.profile_picture = res.data.profile_picture
            })
        },
        tab(e,i){
            localStorage.setItem('tab',i)
            this.activeIndex = i
            window.open(e.path,'_self')
            
        },
        gologin(){
            this.$router.push({
                path:'/login'
            })
            localStorage.setItem('tab',0)
        },
        logOut(){
            localStorage.removeItem('token')
            localStorage.setItem('tab',0)
            window.open('/','_self')
        }
    }
}
</script>

<style lang="scss" scoped>
.container{
    position: fixed;
    width: 100%;
    z-index: 999;
}
  ul{
    width: 100%;
    height: 4rem;
    line-height: 4rem;
    display: flex;
    list-style: none;
    background: #000;
    padding: 0 10%;
    box-sizing: border-box;
    align-items: center;
    justify-content: space-between;
    div{
        display: flex;
    }
    li{
        color: rgb(126, 126, 126);
        width: 6rem;
        font-size: 0.9rem;
        span{
            cursor: pointer;
        }
    }
    .login{
        width: auto;
        float: right;
        height: 3rem;
        display: flex;
        align-items: center;
        color: #fff;
        cursor: pointer;
        img{
            width: 3rem;
            height: 3rem;
            border-radius: 50%;
            margin-right: 1rem;
        }
    }
    .actives{
        color: #fff;
    }
  }
</style>