<template>
  <div id="app">
      <header-nav v-if="this.$route.name!='login'" />
      <router-view style="padding: 6rem 10%;"/>
    <!-- <video src="./assets/video3.mp4" autoplay loop  muted  style="width:100%"></video>
    <div style="position: absolute;top:0;left:0;z-index: 99999;width: 100%;">
    </div> -->
    
  </div>
</template>
<script>
import headerNav from './components/header.vue'
export default{
  components:{
    headerNav
  },
  mounted(){
  }
}
</script>
<style>
*{
  padding: 0;
  margin: 0;
	font-family: Avenir, Helvetica, Arial, sans-serif;
}


html,
body { 
  height: 90vh;
  background: url(./assets/wall.jpeg) repeat;
  background-size: cover;
  width: 100%;
  position: relative;
  color: #fff;
  z-index: -1;
  background-attachment: fixed;
  /* background: #f0f0f0; */
  /* filter:progid:DXImageTransform.Microsoft.BasicImage(graysale=1);
    -webkit-filter: grayscale(100%); */

}
 .active{
  background: #1e1f26 !important;
  color: #fff;
 }
 .el-pagination{
  text-align: center !important;
 }
</style>
