import { render, staticRenderFns } from "./view.vue?vue&type=template&id=5b769d7a&scoped=true&"
import script from "./view.vue?vue&type=script&lang=js&"
export * from "./view.vue?vue&type=script&lang=js&"
import style0 from "./view.vue?vue&type=style&index=0&id=5b769d7a&prod&lang=scss&scoped=true&"
import style1 from "./view.vue?vue&type=style&index=1&id=5b769d7a&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b769d7a",
  null
  
)

export default component.exports