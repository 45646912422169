<template>
  <div class="content_box">
    <div class="banner">
      <p>{{ banner.type_zh }}</p>
      <div style="display: flex">
        <p style="width: 70%">{{ banner.desc }}</p>
        <dv-scroll-board
          :config="config"
          style="width: 30%; height: 220px; margin-left: 2rem"
        />
      </div>

      <p v-show="banner.start_time">
        比赛时间：{{ banner.start_time }} - {{ banner.end_time }}
      </p>
      <van-count-down
        :time="Number(endTimer - nowHaomiao)"
        v-show="banner.start_time"
      >
        <template v-slot="timeData">
          <span
            style="
              font-size: 1rem;
              margin-right: 0.5rem;
              width: 33px;
              background: none;
              color: #fff;
            "
            >距比赛结束
            <span style="color: #ee0a24; margin: 0 0.5rem">{{
              timeData.days > 9 ? timeData.days : `0${timeData.days}`
            }}</span>
            天</span
          >
          <span class="blocks">{{
            timeData.hours > 9 ? timeData.hours : `0${timeData.hours}`
          }}</span>
          <span
            style="font-size:0.28rem;!important;color: #fff;font-weight: 600;"
            >:</span
          >
          <span class="blocks">{{
            timeData.minutes > 9 ? timeData.minutes : `0${timeData.minutes}`
          }}</span>
          <span
            style="font-size:0.28rem;!important;color: #fff;font-weight: 600;"
            >:</span
          >
          <span class="blocks"
            >{{
              timeData.seconds > 9 ? timeData.seconds : `0${timeData.seconds}`
            }}
          </span>
        </template>
      </van-count-down>
    </div>
    <ul class="tab">
      <li
        @click="(query_sort = ''), (page = 1), getData(), (activeIndex = 111)"
        :class="activeIndex == 111 ? 'actives' : ''"
      >
        全部
      </li>
      <li
        v-for="(item, index) in tab"
        :key="index"
        :class="index == activeIndex ? 'actives' : ''"
        @click="changeTab(item, index)"
      >
        {{ item.type_en }}
      </li>
    </ul>
    <div class="contents">
      <el-row :gutter="10" v-if="RangeDate.length == 0">
        <el-col :span="10" :offset="7"
          ><el-image
            style="width: 60%; margin: auto; display: block"
            :src="url"
          ></el-image>
          <p style="text-align: center">暂无数据</p>
        </el-col>
      </el-row>
      <div class="box">
        <div
          class="grid-content bg-purple"
          v-for="item in RangeDate"
          :key="item.id"
          @click="godetail(item.id)"
        >
          <span v-show="item.status" class="gongke"> 已攻克 </span>
          <div class="image_vul_name" style="padding: 1rem 2.5rem">
            <div class="image_vul_name_title_box">{{ item.score }}</div>
            <!-- <img :src="item.image_cover" style="width:100%;height:11rem" alt=""> -->
            <h6 class="Score">Score</h6>
            <div style="font-size: 0.8rem">
              <p class="image_vul_name_title">{{ item.challenge_name }}</p>
              <p class="type">{{ item.challenge_lable }}</p>
              <p style="color: #b7bbc8">
                <span>难度：</span>
                <el-rate
                  v-model="item.rank"
                  disabled-void-color="#ccc"
                  disabled
                  id="difficulty"
                  text-color="#f7ba2a"
                >
                </el-rate>
              </p>
              <p style="color: #b7bbc8">
                攻克人数:
                <span style="margin-left: 10px">{{ item.solve_num }}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <el-row class="block">
        <el-pagination
          background
          :page-size="unit"
          :current-page="page"
          @current-change="changePage"
          layout="prev, pager, next"
          :total="count"
        ></el-pagination>
      </el-row>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import utils from "@/components/utils";
import complete from "@/assets/complete.png";
import url from "../../assets/VectorSmartObject.png";
export default {
  name: "shootingRange",
  components: {
    utils,
  },
  data() {
    return {
      endTimer: "",
      nowHaomiao: new Date().getTime(), //当前毫秒数
      startTimer: "",
      loading: false,
      complete: complete,
      flag: "",
      unit: 9, //每页显示多少数据
      page: 1, //初始显示第几页
      count: 0, //总条数
      dialogFormVisible: false, //弹框
      RangeDate: [],
      searchRangeDate: [],
      allRangeDate: [],
      dialogData: [],
      item: {},
      image_id: "",
      data_status: [],
      imgid: "",
      flag_status: "",
      vul_host_tmp: "",
      difficulttype: null,
      reset_box: [
        "如果有问题请点击重置！",
        "如果有问题请点击重置！",
        "如果有问题请点击重置！",
      ],
      url: url,
      notPurchased: false,
      //用于分类搜索
      query_sort: "",
      tab: [],
      activeIndex: 111,
      banner: {},
      config: { data: {} },
      solve_type: "",
      new_solve: null,
    };
  },
  filters: {
    status(val) {
      return val == "running" ? "运行中" : "未启动";
    },
  },
  created() {
    if (this.$route.query.code) {
      this.$get("ctfd/oauth_login", {
        code: this.$route.query.code,
      }).then((res) => {
        localStorage.setItem("token", res.data.token);
      });
    }
    this.getData();
    this.gettab();
    this.getBanner();
    // this.getSolve_type();
  },
  methods: {
    getNew() {
      this.$get("ctfd/get_new_solve", {
      }).then((res) => {
        var arr = [];
        this.config = {
          data: [],
          headerBGC: "#07072d",
          oddRowBGC: "rgba(46, 46, 46,0.7)",
          evenRowBGC: "rgba(109, 109, 115,0.7)",
          hoverPause: true,
        };
        res.map((item) => {
          arr.push(
            item.username +
              `<span style="color:#fccc46"> 已攻克 </span>` +
              item.challenge_name
          );
          this.config["data"].push(arr);
          arr = [];
        });
      });
    },
    getSolve_type() {
      this.$get("ctfd/get_race_info").then((res) => {
        this.solve_type = res.data.type_en;
        this.getNew();
        this.new_solve = setInterval(() => {
          this.getNew();
        }, 20000);
      });
    },
    getBanner() {
      this.$get("ctfd/get_race_info").then((res) => {
        this.banner = res.data;
        var timer = res.data.end_time;
        timer = timer.replace(/年/, " ");
        timer = timer.replace(/月/, " ");
        timer = timer.replace(/日/, " ");
        this.endTimer = new Date(timer).getTime();
				this.solve_type = res.data.type_en;
        this.getNew();
        this.new_solve = setInterval(() => {
          this.getNew();
        }, 20000);
      });
    },
    changeTab(e, i) {
      this.activeIndex = i;
      this.query_sort = e.type_en;
      this.page = 1;
      this.getData();
    },
    gettab() {
      this.$get("ctfd/challenges/type").then((res) => {
        this.tab = res.results;
      });
    },
    change(val) {
      this.$post("image/list/", { ImageId: val }).then((res) => {
        this.dialogData = res.data.data;
        this.data_status = res.data.status;
        this.flag_status = res.data.flag_status;
        if (res.data.status.length != 0) {
          this.vul_host_tmp = res.data.status[0].vul_host;
        }
      });
      this.dialogFormVisible = true;
    },
    godetail(val) {
      if (localStorage.getItem("token")) {
        this.$router.push({ path: "/launchRange", query: { id: val } });
      } else {
        this.$router.push({
          path: "/login",
        });
      }
    },
    // 重置
    resetBtn() {
      this.$get("container/reset/").then((res) => {
        if (res.status == 200) {
          this.$notify.success({
            title: "成功",
            message: "您已重置",
          });
        }
        //TODO:强制刷新
        this.$router.go(0);
      });
    },
    //请求列表数据
    getData() {
      let params = null;
      params = { query: this.query_sort, page: this.page, size: 9 };
      this.$get("ctfd/challenges", params).then((res) => {
        this.count = res.count;
        this.RangeDate = res.results;
        this.searchRangeDate = res.results;
        this.allRangeDate = res.results;
      });
    },

    // 分页
    changePage(e) {
      this.page = e;
    },
  },
  computed: {
    target_ip() {
      if (this.vul_host_tmp == "undefined") {
        return null;
      } else {
        var tmp_url = this.vul_host_tmp.split(":");
        var tmp_port = tmp_url[2].split(",");
        var final_url = new Array();
        for (var i = 0; i < tmp_port.length; i++) {
          var final_tmp_url = tmp_url[0] + ":" + tmp_url[1] + ":" + tmp_port[i];
          final_url.push(final_tmp_url);
        }
        return final_url;
      }
    },
  },
  mounted() {
    this.imgid = utils.getUrlKey("id");
    if (this.imgid) {
      this.change(this.imgid);
      return false;
    }
  },
  watch: {
    page(val) {
      this.getData();
    },
  },
  beforeDestroy() {
    clearInterval("new_solve");
  },
};
</script>

<style lang="scss" scoped>
.banner {
  color: #fff;
  p:nth-child(1) {
    font-weight: bold;
    text-align: center;
    font-size: 1.3rem;
    margin-bottom: 2rem;
  }
  div {
    p:nth-child(1) {
      white-space: break-spaces;
      font-size: 0.9rem;
      text-align: left;
      font-weight: normal;
    }
  }

  p:nth-child(3) {
    font-size: 1rem;
    text-align: center;
    margin: 2rem 0;
  }
}
.tab {
  display: flex;
  list-style: none;
  color: #fff;
  margin-bottom: 3rem;
  li {
    color: #b7bbc8;
    background: hsla(223deg, 14%, 20%, 1);
    padding: 0.5rem 1rem;
    cursor: pointer;
    margin-right: 1rem;
    border-radius: 4px;
  }
  .actives {
    color: #fff;
    background: hsla(228deg, 12%, 40%, 1);
  }
}
a {
  color: #ffffff !important;
}
a:hover {
  color: rgb(255, 208, 75) !important;
}
.el-button:hover a {
  color: #000000 !important;
}
.content_box {
  overflow: auto;
  background-size: cover;
}
::v-deep {
}
.contents {
  width: 100%;
  position: relative;
}
.ReadMore {
  color: #fff;
  background: #3a3a3a;
  display: block;
  text-align: center;
  font-size: 0.875em;
  cursor: pointer;
  line-height: 60px;
  height: 60px;
  transition: all 0.5s ease-in 0.1s;
}
.ReadMore:hover {
  color: rgb(255, 208, 75) !important;
  font-size: 16px;
}
.box {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.box::after {
  content: "";
  width: 30%;
  height: 0;
  visibility: hidden;
}
.image_vul_name {
  position: relative;
  opacity: 0.8;
}

.image_vul_name_title_box {
  font-size: 40px;
  text-align: center;
  font-family: "FZYaSongS-B-GB", cursive;
  font-weight: bold;
}
.type {
  display: inline-block;
  padding: 0.2rem 0.5rem;
  margin-bottom: 0.5rem;
  border-radius: 20px;
  font-size: 0.8rem;
  background: #fccc46;
  color: #000;
}
.Score {
  margin: 0 auto 20px;
  font-family: "Georgia", cursive;
  font-weight: 500;
  width: 100%;
  word-break: Break-word;
  text-align: center;
  color: #fccc46 !important;
  font-size: 22px;
}
.image_vul_name {
  color: #fff;
  padding-bottom: 2rem !important;
  background: #1e1f26;
}
.bg-purple {
  width: 28%;
  margin-bottom: 3rem;
  border-radius: 20px;
  overflow: hidden;
  position: relative;
}
.bg-purple .image_vul_name_title {
  line-height: 50px;
  height: 50px;
  font-size: 18px;
  font-weight: bold;
  overflow: hidden; /* 超出一行文字自动隐藏 */
  text-overflow: ellipsis; /*文字隐藏后添加省略号*/
  white-space: nowrap; /*强制不换行*/
}
// >>> .grid-content {
//   background-size: 100% 100%;
//   margin: 1% auto;
//   color: #fff;
//   border-radius: 15px;
//   border: 1px solid #7e7e7e;
//   overflow: hidden;
//   padding-bottom: 3rem;
//   cursor: pointer;
// }
.image_vul_name {
  cursor: pointer;
}
.block{
  margin-top: 2rem;
}
>>> .block .el-pagination {
  text-align: center;
}
>>> .el-col-8 {
  margin: 1% auto;
}
>>> input,
.el-pager,
.more,
.btn-next,
.btn-prev {
  background-color: transparent !important;
  color: #ffffff !important;
}
>>> .number,
>>> .el-input-group__append {
  background-color: transparent !important;
}
>>> .page {
  margin-bottom: 3vh !important;
  width: 100% !important;
}
>>> .el-pagination .btn-next,
>>> .el-pagination .btn-prev,
>>> .el-dialog,
>>> .el-pager li {
  // background: transparent !important;
}
/* 弹框 */
>>> #complete_box {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  overflow: hidden;
}
>>> .el-dialog {
  background-size: 100% 100% !important;
  background-color: rgba(30, 31, 32, 0.9) !important;
  background-repeat: no-repeat !important;
  border: 1px solid #ffffff !important;
}
>>> .el-dialog__wrapper {
  height: 100vh !important;
}
>>> .el-dialog__title,
>>> .el-dialog__body,
>>> .el-dialog__headerbtn .el-dialog__close,
>>> .el-dialog__body .el-button {
  color: #ffffff !important;
  overflow: hidden !important;
}
>>> .el-dialog__body {
  overflow: auto !important;
  height: 70vh !important;
}
>>> .el-dialog__header {
  border-bottom: 1px solid #ffffff;
}
>>> .el-dialog__body .el-button {
  width: 100% !important;
  text-align: center !important;
  border: 1px solid #ffffff !important;
  margin: auto !important;
  display: block !important;
  margin-top: 30px !important;
  letter-spacing: 5px !important;
}
>>> .el-dialog__body .el-button:hover {
  background: #ffffff;
  color: #000000 !important;
}
.ShootDetails p {
  line-height: 35px;
}
.ShootDetails span {
  display: inline-block;
  vertical-align: middle;
}
>>> .el-input__inner {
  height: 40px !important;
  line-height: 40px !important;
}
.mirrname {
  display: inline-block;
  width: 12%;
  text-align: right;
}
.gongke {
  position: absolute;
  z-index: 9;
  right: 0;
  background: rgba(252, 204, 70, 0.7);
  color: #000;
  font-size: 0.8rem;
  padding: 0.3rem 0.5rem;
  border-bottom-left-radius: 20px;
}
.mirrdata {
  display: inline-block;
  width: 84%;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
>>> .el-input {
  width: 100% !important;
}
.inputborter {
  border-bottom: 0px !important;
}
.complete {
  position: absolute;
  width: 15%;
  right: 6%;
  top: 12%;
  transform: rotate(-12deg);
  -ms-transform: rotate(-12deg); /* IE 9 */
  -moz-transform: rotate(-12deg); /* Firefox */
  -webkit-transform: rotate(-12deg); /* Safari 和 Chrome */
  -o-transform: rotate(-12deg); /* Opera */
  z-index: 999;
}
// 按钮
>>> .el-input-group__append {
  padding: 0px !important;
}
>>> #submit_btn {
  margin-top: 0px !important;
  margin-left: 0px !important;
  border: none !important;
  width: 100% !important;
}
>>> #btn_box {
  text-align: center;
  overflow: hidden;
  margin-bottom: 3%;
  color: #000;
  display: flex;
}
>>> #download_btn {
  text-align: center;
  cursor: pointer;
  width: 30% !important;
  margin-left: 15% !important;
}
>>> #manual_btn {
  width: 30% !important;
  margin-left: 10% !important;
}

#changeBtns {
  width: 60% !important;
  margin-left: 20%;
}
.flag_box {
  margin-top: 10px;
}

.comein {
  animation-name: imageAnim;
  animation-duration: 0.5s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: ease;
  animation-play-state: running;
  color: #fff;
  line-height: 5rem;
}
@keyframes imageAnim {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
    color: #f7ba2a;
  }
  100% {
    opacity: 0;
  }
}
>>> .tag-group {
  margin-bottom: 1rem;
  overflow: hidden;
}
>>> .tag-group__title {
  float: left;
}
>>> .tag_content {
  float: left;
  width: 90%;
}
>>> .tag_content .el-button {
  margin-bottom: 0.5rem;
  margin-right: 0.5rem;
}
>>> .el-carousel__item {
  color: #d3d3d3;
}
>>> .el-carousel__indicator--vertical {
  display: none;
}
>>> .el-button--text {
  color: #fff;
}
#difficulty {
  display: inline-block;
}
>>> .el-button--primary,
>>> .el-button--info,
>>> .el-button--success,
>>> .el-button--warning {
  background-color: transparent !important;
}
>>> #btn_box .el-button {
  background: #000;
}
>>> #btn_box .el-button:hover {
  background: #fff;
}
>>> #changeBtns .el-button {
  background: #000;
}
>>> #changeBtns .el-button:hover {
  background: #fff;
}
>>> .active {
  background: #000 !important;
}
</style>
<style>
.el-carousel__indicator--vertical {
  display: none;
}
.block {
  margin-top: 5rem;
}
.blocks {
  display: inline-block;
  width: 22px;
  color: #fff !important;
  font-size: 1rem;
  text-align: center;
  /* background-color: #f04b5e; */
}
.van-count-down {
  text-align: center;
  margin-bottom: 2rem;
}
</style>